import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Heading, Text } from "@atoms"

import { useState } from "react"
import ModalVideo from "react-modal-video"
import { VideoFrame } from "../../styles/components/VideoFrame"
import OptimizedImage from "../../utils/optimizedImage"

import {
  HomeHeroWrapper,
  ImageWrapper,
  ThumbnailWrapper,
  HeroContent,
} from "./styles/HeroVideoLoop.styled"
import Breadcrumbs from "./components/Breadcrumbs"

import { validatePlay } from "../../utils/validateVideo"
import onKeyDown from "../../utils/onKeyDown"
import { BreadcrumbContainer } from "./styles/Breadcrumbs.styled"

const HeroVideoLoop = ({ component, location, seoBreadcrumbs }) => {
  const {
    id,
    heading,
    headingSize,
    subheading,
    videoUrl,
    background,
    videoThumbnail,
    featuredImage,
    breadcrumbs,
    breadcrumbTitle,
  } = component

  const video = { videoUrl: videoUrl }

  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [channel, setChannel] = useState("")

  const playVideo = video => {
    validatePlay(video, setChannel, setVideo, setPlay)
  }

  return (
    <HomeHeroWrapper background={background}>
      {breadcrumbs && (
        <BreadcrumbContainer video>
          <Breadcrumbs
            background={background}
            location={location}
            seoBreadcrumbs={seoBreadcrumbs}
            breadcrumbTitle={breadcrumbTitle}
          />
        </BreadcrumbContainer>
      )}
      <HeroContent>
        <OptimizedImage
          className="background-image"
          loading="eager"
          image={featuredImage?.gatsbyImageData}
          src={featuredImage?.file?.url}
          alt=""
        />
        <div className="home-hero--container">
          <div className="home-hero--copy-container">
            {heading && (
              <Heading
                background={background}
                headingSize={headingSize}
                className="home-hero--heading"
              >
                {heading}
              </Heading>
            )}
            {subheading?.raw && (
              <Text
                className="home-hero--description"
                textVariant={background === "light" ? "dark" : "light"}
              >
                {documentToReactComponents(
                  JSON.parse(component?.subheading?.raw)
                )}
              </Text>
            )}
          </div>
          <div className="home-hero--featured-image-container">
            <ImageWrapper>
              <ThumbnailWrapper>
                <figure className="blog-image-wrapper">
                  {videoThumbnail?.file.url ? (
                    <div>
                      <OptimizedImage
                        image={videoThumbnail?.gatsbyImageData}
                        src={videoThumbnail?.file?.url}
                        alt={heading || "video  thumbnail"}
                      />
                    </div>
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span
                    onClick={() => playVideo(video)}
                    role="button"
                    aria-label="play"
                    tabIndex={0}
                    onKeyDown={e =>
                      onKeyDown(e, () => playVideo(video), "Enter")
                    }
                  >
                    <img src="/icons/hero-play.svg" alt="watch video button" />
                  </span>
                </div>
              </ThumbnailWrapper>
            </ImageWrapper>
          </div>
        </div>
      </HeroContent>
      {typeof window !== "undefined" && (
        <VideoFrame>
          <ModalVideo
            channel={channel}
            isOpen={play}
            videoId={videoId}
            url={channel === "custom" && videoId}
            onClose={() => setPlay(false)}
            autoplay={true}
          />
        </VideoFrame>
      )}
    </HomeHeroWrapper>
  )
}

HeroVideoLoop.displayName = "HeroVideoLoop"
export default HeroVideoLoop
