import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { BreadcrumbWrapper } from "../styles/Breadcrumbs.styled"
import Breadcrumb from "./Breadcrumb"
import { CrumbDivider } from "../utils/icons"
import {
  formatUrl,
  formatLabel,
  formatSeoUrl
} from "../utils/index"

const BreadcrumbContent = ({ seoBreadcrumbs, paths, breadcrumbTitle }) => {
  return seoBreadcrumbs ?
    (seoBreadcrumbs.map((bc, index) => (
      <Breadcrumb url={formatSeoUrl(seoBreadcrumbs, index)} label={bc?.title} />
    ))) :
    (paths?.map((path, index) => {
      const isLast = index === paths.length - 1
      const label = (isLast && breadcrumbTitle) ? breadcrumbTitle : formatLabel(path)

      return (
        <Breadcrumb url={formatUrl(paths, index)} key={index} label={label} />
      )
    }))
}

const Breadcrumbs = ({ background, location, seoBreadcrumbs, breadcrumbTitle }) => {

  const initialPaths =
    location?.pathname?.split('/').filter(item => item !== '') || [];
  const [paths, setPaths] = useState(initialPaths);

  useEffect(() => {
    if (!location) {
      setPaths(window.location.pathname.split('/').filter(item => item !== ''));
    }
  }, [location]);

  return (
    <BreadcrumbWrapper background={background}>
      <Link to="/">Home</Link>
      <CrumbDivider />
      <BreadcrumbContent seoBreadcrumbs={seoBreadcrumbs} paths={paths} breadcrumbTitle={breadcrumbTitle} />
    </BreadcrumbWrapper>
  )
}

export default Breadcrumbs