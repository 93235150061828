import styled from "styled-components"

export const HomeHeroWrapper = styled.div`
  background-size: cover;
  background-position: center;
  font-family: "Roboto";
  position: relative;
  font-family: "Roboto";
  position: relative;
  .background-image {
    position:absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .home-hero--container {
    position: relative;
  }
  .home-hero--copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .home-hero--heading {
      font-weight: 900;
      font-size: 68px;
      line-height: 72px;
      text-align: center;
      color: #ffffff;
      @media (max-width: 768px) {
        font-size: 44px;
        line-height: 48px;
      }
      @media (max-width: 576px) {
        font-size: 38px;
        line-height: 44px;
      }
    }
    .home-hero--description {
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      margin: 16px 0 0;
      max-width: 770px;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .home-hero--featured-image-container {
    position: absolute;
    width: 100%;
    max-width: 1170px;
    margin-top: 72px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 576px) {
      margin-top: 56px;
    }
  }
`

export const HeroContent = styled.div`
  padding: 200px 60px 400px;
  margin-bottom: 400px;
  @media (max-width: 992px) {
    padding: 180px 40px 300px;
    margin-bottom: 350px;
  }
  @media (max-width: 775.98px) {
    margin-bottom: 250px;
  }
  @media (max-width: 576px) {
    padding: 180px 20px 185px;
    margin-bottom: 200px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  .blog-image-wrapper {
    width: 100%;
    max-height: 600px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      object-fit: cover;
    }
    :after {
      content: "";
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.5) 49.48%,
          rgba(0, 0, 0, 0.5) 100%
        );
    }
    .emptyImg {
      width: 100%;
      max-height: 800px;
      min-height: 450px;
      background: #d4d9e2;
      @media (max-width: 992px) {
        width: 100%;
        min-width: auto;
        min-height: auto;
      }
    }
  }
`
export const ThumbnailWrapper = styled.div`
  .playicon{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 992px) {
      top: 55%;
    }
    span{
    display: inline-block;
    cursor: pointer;
    line-height: 72px;
    img{
      width:150px;
      height:150px;
      }
    }
  }
`
