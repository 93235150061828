export const formatLabel = path => {
  return path
    .split('-')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');
};

export const formatUrl = (
  paths,
  index,
) => {
  const lastBreadcrumb = index === paths.length - 1;

  return !lastBreadcrumb && `/${paths.slice(0, index + 1).join('/')}`;
};

export const formatSeoUrl = (
  seoBreadcrumbs,
  index,
) => {
  const lastBc = index === seoBreadcrumbs.length - 1;

  return !lastBc && `${seoBreadcrumbs.slice(0, index + 1)[index].url}/`
}