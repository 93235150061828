import React from "react"
import { Link } from "gatsby"
import { CrumbDivider } from "../utils/icons"

const Breadcrumb = ({ url, label }) => {
  if (!url) {
    return (
      <div className="disabled">
        {label}
      </div>
    );
  }

  return (
    <>
      <Link to={url}>
        {label}
      </Link>
      <CrumbDivider />
    </>
  );
}

export default Breadcrumb