import styled from "styled-components"
import { buttonThemes, color } from "../../../atoms/colors"
import { font } from "../../../atoms/typography"

export const BreadcrumbWrapper = styled.div`
  z-index: 10;
  text-align: left;
  div, a {
    display: inline-block;
    ${font('text', 'sm', '400')}
  }
  a {
    ${({ background }) => background !== "light" ? `
      ${buttonThemes.linkLight}` : `${buttonThemes.link};
    `}
    text-decoration: none;
  }
  .divider {
    margin: 0 12px;
  }
  .disabled {
    ${({ background }) => background !== "light" ? `
      color: ${color.grey[300]};` : `color: ${color.grey[900]};
    `}
  }
`

export const BreadcrumbContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  align-items: center;
  padding: 25px 15px 0 15px;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`
